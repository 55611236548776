import Base from './base';

class Main extends Base {
  login = (data) => this.postRequest('/login', data)
  refreshToken = data => this.postRequest('/token/refresh', data)
  logOff = (data) => this.postRequest('/logout', data)

  fileUpload = data => this.postRequest(`/file/upload`, data)
  aboutUsUpload = data => this.postRequest(`/file/upload/about-us`, data)
  tcUpload = data => this.postRequest(`/file/upload/tc`, data)
  getFile = id => this.getRequest(`/file/download/thumbnail/${id}`)

  changePassword = (data) => this.putRequest('/users/password', data)

}

export default new Main();
