import React from 'react';
import { WrapperSeamless } from '../../layout-components';
import Table from '../../components/tables/table';
import { Button, Container, Box } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetList } from '../../hooks';
import { Link } from 'react-router-dom';
import * as Api from '../../service';
import useStore from '../../context';

const fields = [
    <th className="text-left px-4">Name</th>,
    <th className="text-left">description</th>,
    <th className="text-left">music album</th>,
    <th className="text-left">status</th>,
    <th className="text-left">price</th>
];

export default function Items() {
    const { setRemove } = useStore();
    const [items, total, refresh, setItems] = useGetList(
        Api.items.getAll,
        Api.items.countAll
    );

    const rows = items?.map((item) => (
        <>
            <td className="text-left">
                <div>
                    <div
                        className="font-size-sm"
                        style={{ width: 'max-content', whiteSpace: 'nowrap' }}>
                        {item.name}
                    </div>
                </div>
            </td>

            <td className="text-left">
                <div className="font-size-sm clamp1">{item.description}</div>
            </td>
            <td className="text-left">
                <div className="font-size-sm clamp1">
                    {item.isMusicAlbum && (
                        <Box component="span" mr={1}>
                            <FontAwesomeIcon icon="check" />
                        </Box>
                    )}
                </div>
            </td>
            <td className="text-left">
                <div className="font-size-sm">{item.status}</div>
            </td>
            <td className="text-left">
                <div className="font-size-sm">{item.basePrice}</div>
            </td>
            <td className="text-right">
                <Link to={`/items/create/${item.id}`}>
                    <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                            icon={['far', 'edit']}
                            className="font-size-sm"
                        />
                    </Button>
                </Link>

                <Button
                    onClick={() =>
                        setRemove(() => () =>
                            Api.items.delete(item.id).then(refresh)
                        )
                    }
                    className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                    <FontAwesomeIcon
                        icon={['fas', 'times']}
                        className="font-size-sm"
                    />
                </Button>
            </td>
        </>
    ));

    return (
        <Container maxWidth>
            <WrapperSeamless sectionHeading="Items">
                <Table
                    title="Items"
                    noDrag
                    items={items}
                    setItems={setItems}
                    total={total}
                    fields={fields}
                    rows={rows}
                    createLink="items/create"
                />
            </WrapperSeamless>
        </Container>
    );
}
