import React, { useState } from 'react';
import { Container, Card, Button } from '@material-ui/core';
import * as Api from '../../service'
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment'

const initState = {
  users: null,
  baskets: null,
  purchases: null
}

const ReportsList = () => {
  const [startDate, setStartDate] = useState(initState);
  const [endDate, setEndDate] = useState(initState);

  const downloadFile = (url) => {
    url
      .then((response) => {
        const dispositionArray = response.disposition.split("=")
        const url = window.URL.createObjectURL(new Blob([response.blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', dispositionArray[1]);
        document.body.appendChild(link);
        link.click();
      });
  }

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">

        <Card className='p-3 m-2 d-flex align-items-center justify-content-between' >
          <span style={{ width: '20%' }}>Users report</span>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around" style={{width: '60%'}}>
              <KeyboardDatePicker
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="start-date"
                label="Start Date"
                value={startDate.users}
                onChange={date => setStartDate(st => ({ ...st, users: date }))}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                minDate={startDate.users}
                id="end-date"
                label="End Date"
                value={endDate.users}
                onChange={date => setEndDate(st => ({ ...st, users: date }))}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
          <Button className="m-2 btn-neutral-primary" onClick={() => downloadFile(Api.report.getUsers({
            dateFrom: moment(startDate.users).format('yyyy-MM-DD HH:mm:ss'),
            dateTo: moment(endDate.users).format('yyyy-MM-DD HH:mm:ss')
          }))}>Download</Button>
        </Card>
        <Card className='p-3 m-2 d-flex align-items-center justify-content-between' >
          <span style={{ width: '20%' }}>Baskets</span>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around" style={{width: '60%'}}>
              <KeyboardDatePicker
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="start-date"
                label="Start Date"
                value={startDate.baskets}
                onChange={date => setStartDate(st => ({ ...st, baskets: date }))}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                minDate={startDate.baskets}
                id="end-date"
                label="End Date"
                value={endDate.baskets}
                onChange={date => setEndDate(st => ({ ...st, baskets: date }))}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
          <Button className="m-2 btn-neutral-primary" onClick={() => downloadFile(Api.report.getPayments({
            dateFrom: moment(startDate.baskets).format('yyyy-MM-DD HH:mm:ss'),
            dateTo: moment(endDate.baskets).format('yyyy-MM-DD HH:mm:ss')
          }))}>Download</Button>
        </Card>
        <Card className='p-3 m-2 d-flex align-items-center justify-content-between' >
          <span style={{ width: '20%' }}>Purchases report</span>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around"  style={{width: '60%'}}>
              <KeyboardDatePicker
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="start-date"
                label="Start Date"
                value={startDate.purchases}
                onChange={date => setStartDate(st => ({ ...st, purchases: date }))}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                minDate={startDate.purchases}
                id="end-date"
                label="End Date"
                value={endDate.purchases}
                onChange={date => setEndDate(st => ({ ...st, purchases: date }))}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
          <Button className="m-2 btn-neutral-primary" onClick={() => downloadFile(Api.report.getPurchasedItems({
            dateFrom: moment(startDate.purchases).format('yyyy-MM-DD HH:mm:ss'),
            dateTo: moment(endDate.purchases).format('yyyy-MM-DD HH:mm:ss')
          }))}>
            Download</Button>
        </Card>
      </Card>

    </Container >
  );
};

export default ReportsList;