import Base from './base';

class Groups extends Base {
    getAll = (query) => this.getRequest('/subscription-plans', query)
    create = data => this.postRequest('/subscription-plans', data)
    getById = (id, data) => this.getRequest(`/subscription-plans/${id}`, data)
    update = (id, data) => this.putRequest(`/subscription-plans/${id}`, data)
    parent = (query) => this.putRequest(`/subscription-plans/parent`, null, query)
    delete = (id) => this.deleteRequest(`/subscription-plans/${id}`)
    getAllActives = () => this.getRequest(`/subscription-plans/actives`)

}

export default new Groups();
