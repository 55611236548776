import React, { useCallback } from 'react';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import useStore from '../context'

const Snack_bar = () => {
    const { successMsg, errorMsg, setSuccessMsg, setErrorMsg } = useStore()

    const close = useCallback(() => {
        setSuccessMsg('')
        setErrorMsg('')
    }, []);

    return (
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={errorMsg || successMsg} autoHideDuration={4000} onClose={close}>
            <Alert onClose={close} severity={successMsg ? 'success' : errorMsg ? 'error' : ''}>
                {errorMsg || successMsg}
            </Alert>
        </Snackbar>
    );
};

export default Snack_bar;