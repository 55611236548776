import React from 'react';
import {
    Container,
    Card,
    TextField,
    Grid,
    Button,
    Box,
    Switch,
    FormControlLabel
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams, useHistory } from 'react-router-dom';
import { useText, useGetData, usePostData, usePutData } from '../../hooks';
import { WrapperSeamless } from '../../layout-components';
import Gallery from '../../components/gallery';
import * as Api from '../../service';
import useStore from '../../context';

const initForm = {
    id: '',
    name: '',
    typeId: '',
    photo: null
};

const MusicAlbumConfigInit = {
    ARTIST_NAME: { isEnabled: false, isRequired: false },
    SONG_NAME: { isEnabled: false, isRequired: false },
    LABEL_NAME: { isEnabled: false, isRequired: false },
    TEXT: { isEnabled: false, isRequired: false },
    COVER_IMAGE: { isEnabled: false, isRequired: false },
    STREAMING_PLATFORMS: { isEnabled: false, isRequired: false }
};

export default function CreateMusicAlbumType() {
    const { id } = useParams();
    const history = useHistory();
    const [newFile, setNewFile] = React.useState(null);
    const { setErrorMsg } = useStore();
    const [data, refresh] = useGetData(id && Api.musicAlbums.getTypeById, id);
    const createCallback = usePostData(Api.musicAlbums.create);
    const updateCallback = usePutData(Api.musicAlbums.update, refresh);
    const [text, setText, textChange] = useText(data || initForm);
    const [musicAlbumConfigs, setMusicAlbumConfigs] = React.useState(MusicAlbumConfigInit);

    React.useEffect(() => {
        if (data) {
            const configs = {};
            data.musicAlbumItemConfigs.forEach((config) => {
                configs[config.mediaKey] = { isEnabled: config.isEnabled, isRequired: config.isRequired };
            });
            setMusicAlbumConfigs(configs);
        }
    }, [data]);

    const onSubmit = React.useCallback(
        async (event) => {
            event.preventDefault();
            if (!text.name) return setErrorMsg('Name field is required');

            try {
                const form = new FormData();
                newFile && form.append('file', newFile[0]);
                newFile && form.append('context', 'MUSIC_ALBUM_TYPE_COVER');

                const url = newFile ? await Api.main.fileUpload(form) : null;

                const musicAlbumConfigsArray = Object.keys(musicAlbumConfigs).map(key => ({
                    mediaKey: key,
                    isEnabled: musicAlbumConfigs[key].isEnabled,
                    isRequired: musicAlbumConfigs[key].isRequired
                }));
                if (id == null) {
                    text.id = undefined;
                }
                const obj = JSON.stringify({
                    ...text,
                    photo: url ? url.url : text.photo,
                    musicAlbumItemConfigs: musicAlbumConfigsArray.filter(config => config.isEnabled)
                });

                if (id) {
                    await updateCallback(id, obj);
                } else {
                    await createCallback(obj);
                }

                history.push('/musicAlbumTypes');
            } catch (e) {
                setErrorMsg(e.message);
            }
        },
        [text, newFile, id, musicAlbumConfigs]
    );

    const toggleMAItemConfigEnable = (key) => {
        const newMusicAlbumItemConfigs = {...musicAlbumConfigs};
        if (newMusicAlbumItemConfigs[key] == null) {
            newMusicAlbumItemConfigs[key] = { isEnabled: true, isRequired: false };
        } else {
            newMusicAlbumItemConfigs[key].isEnabled = !newMusicAlbumItemConfigs[key].isEnabled;
        }
        setMusicAlbumConfigs(newMusicAlbumItemConfigs);
    };
    const toggleMAItemConfigRequire = (key) => {
        const newMusicAlbumItemConfigs = {...musicAlbumConfigs};
        if (newMusicAlbumItemConfigs[key] == null) {
            newMusicAlbumItemConfigs[key] = { isEnabled: true, isRequired: false };
        } else {
            newMusicAlbumItemConfigs[key].isRequired = !newMusicAlbumItemConfigs[key].isRequired;
        }
        setMusicAlbumConfigs(newMusicAlbumItemConfigs);
    };

    return (
        <Container maxWidth>
            <WrapperSeamless
                backUrl="/musicAlbumTypes"
                sectionHeading={`${id == null ? "Create" : "Edit"} Music Album Type`}>
                <Card className="shadow-xxl px-4 py-2">
                    <Box component="form" onSubmit={onSubmit}>
                        <Grid container direction="column">
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    className="my-2"
                                    label="Type ID"
                                    required
                                    fullWidth
                                    name="typeId"
                                    value={text.typeId}
                                    onChange={textChange}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    className="my-2"
                                    label="Name"
                                    required
                                    fullWidth
                                    name="name"
                                    value={text.name}
                                    onChange={textChange}
                                />
                            </Grid>
                            <Grid item>
                                <Grid container spacing={4} direction="row" maxWidth>
                                    <Grid item>
                                        <Grid container spacing={2} direction="column">
                                            <Grid item>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item style={{width: "120px"}}><strong>Artist name:</strong></Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            value="top"
                                                            control={<Switch color="primary" className="switch-small" onChange={() => toggleMAItemConfigEnable("ARTIST_NAME")} checked={musicAlbumConfigs["ARTIST_NAME"]?.isEnabled} />}
                                                            label="Enable"
                                                            style={{fontSize: "11px"}}
                                                            labelPlacement="top"
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            value="top"
                                                            control={<Switch color="primary" className="switch-small" onChange={() => toggleMAItemConfigRequire("ARTIST_NAME")} checked={musicAlbumConfigs["ARTIST_NAME"]?.isRequired} />}
                                                            label="Require"
                                                            labelPlacement="top"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item style={{width: "120px"}}><strong>Song name:</strong></Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            value="top"
                                                            control={<Switch color="primary" className="switch-small" onChange={() => toggleMAItemConfigEnable("SONG_NAME")} checked={musicAlbumConfigs["SONG_NAME"]?.isEnabled} />}
                                                            label="Enable"
                                                            style={{fontSize: "11px"}}
                                                            labelPlacement="top"
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            value="top"
                                                            control={<Switch color="primary" className="switch-small" onChange={() => toggleMAItemConfigRequire("SONG_NAME")} checked={musicAlbumConfigs["SONG_NAME"]?.isRequired} />}
                                                            label="Require"
                                                            labelPlacement="top"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item style={{width: "120px"}}><strong>Label name:</strong></Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            value="top"
                                                            control={<Switch color="primary" className="switch-small" onChange={() => toggleMAItemConfigEnable("LABEL_NAME")} checked={musicAlbumConfigs["LABEL_NAME"]?.isEnabled} />}
                                                            label="Enable"
                                                            style={{fontSize: "11px"}}
                                                            labelPlacement="top"
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            value="top"
                                                            control={<Switch color="primary" className="switch-small" onChange={() => toggleMAItemConfigRequire("LABEL_NAME")} checked={musicAlbumConfigs["LABEL_NAME"]?.isRequired} />}
                                                            label="Require"
                                                            labelPlacement="top"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={2} direction="column">
                                            <Grid item>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item style={{width: "170px"}}><strong>Text:</strong></Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            value="top"
                                                            control={<Switch color="primary" className="switch-small" onChange={() => toggleMAItemConfigEnable("TEXT")} checked={musicAlbumConfigs["TEXT"]?.isEnabled} />}
                                                            label="Enable"
                                                            style={{fontSize: "11px"}}
                                                            labelPlacement="top"
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            value="top"
                                                            control={<Switch color="primary" className="switch-small" onChange={() => toggleMAItemConfigRequire("TEXT")} checked={musicAlbumConfigs["TEXT"]?.isRequired} />}
                                                            label="Require"
                                                            labelPlacement="top"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item style={{width: "170px"}}><strong>Cover image:</strong></Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            value="top"
                                                            control={<Switch color="primary" className="switch-small" onChange={() => toggleMAItemConfigEnable("COVER_IMAGE")} checked={musicAlbumConfigs["COVER_IMAGE"]?.isEnabled} />}
                                                            label="Enable"
                                                            style={{fontSize: "11px"}}
                                                            labelPlacement="top"
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            value="top"
                                                            control={<Switch color="primary" className="switch-small" onChange={() => toggleMAItemConfigRequire("COVER_IMAGE")} checked={musicAlbumConfigs["COVER_IMAGE"]?.isRequired} />}
                                                            label="Require"
                                                            labelPlacement="top"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item style={{width: "170px"}}><strong>Streaming platforms:</strong></Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            value="top"
                                                            control={<Switch color="primary" className="switch-small" onChange={() => toggleMAItemConfigEnable("STREAMING_PLATFORMS")} checked={musicAlbumConfigs["STREAMING_PLATFORMS"]?.isEnabled} />}
                                                            label="Enable"
                                                            style={{fontSize: "11px"}}
                                                            labelPlacement="top"
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            value="top"
                                                            control={<Switch color="primary" className="switch-small" onChange={() => toggleMAItemConfigRequire("STREAMING_PLATFORMS")} checked={musicAlbumConfigs["STREAMING_PLATFORMS"]?.isRequired} />}
                                                            label="Require"
                                                            labelPlacement="top"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Gallery
                                    title="Type photo"
                                    single
                                    images={text.photo}
                                    setNewFile={setNewFile}
                                    setImages={(img) =>
                                        setText((st) => ({ ...st, photo: img }))
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="btn-primary m-2">
                                    <span className="btn-wrapper--label">
                                        Save
                                    </span>
                                    <span className="btn-wrapper--icon">
                                        <FontAwesomeIcon
                                            icon={['far', 'save']}
                                        />
                                    </span>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </WrapperSeamless>
        </Container>
    );
}
