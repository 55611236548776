import React, { useState, useCallback, useEffect } from 'react';
import { Container, Button, TextField, Card, Box, List, ListItem, IconButton } from '@material-ui/core';
import Gallery from '../../components/gallery'
import { useParams } from 'react-router-dom'
import { FlagIcon } from 'react-flag-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useText, useGetData, usePutData, usePostData } from '../../hooks'
import useStore from '../../context'
import Select from '../../components/select'
import * as Api from '../../service'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ClearIcon from '@material-ui/icons/Clear';


const Form = () => {
  const { setRemove, setSuccessMsg, setErrorMsg } = useStore()
  const [tags, setTags] = useState(['test', 'esim']);
  const [title, setTitle] = useState('');

  const getTags = useCallback(() => {
    Api.tags.getAll()
      .then(res => setTags(res.reverse()))
  }, []);

  const submitForm = useCallback(async (e) => {
    try {
      e.preventDefault()

      await Api.tags.addNew(JSON.stringify({ name: title }))
      setTitle('')
      setSuccessMsg('created')
      getTags()
    } catch (e) {
      setErrorMsg(e.message)
    }
  }, [title]);

  useEffect(getTags, []);

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component='form' onSubmit={submitForm}>

          <Card className='d-flex p-3 align-items-center'>
            <TextField
              multiline
              variant="outlined"
              className="my-2"
              label='tag title'
              fullWidth
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
            <IconButton className='ml-2' style={{ height: 58 }} onClick={submitForm} >
              <AddCircleIcon color='primary' fontSize='large' />
            </IconButton>
          </Card>

          {tags?.map(el =>
            <Card key={el.id} className='px-2 my-2 d-flex align-items-center'>
              <span>{el.name}</span>
              <IconButton onClick={() => setRemove(() => () => Api.tags.delete(el.id).then(getTags))} className='ml-auto' >
                <ClearIcon color='error' fontSize='medium' />
              </IconButton>
            </Card>
          )}

        </Box>
      </Card>

    </Container >
  );
};

export default Form;