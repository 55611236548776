import Base from './base';

class Items extends Base {
    getAll = query => this.getRequest('/guest/item/search', query)
    countAll = () => this.getRequest('/guest/item/search/count')
    getById = id => this.getRequest(`/guest/item/${id}`)

    create = data => this.postRequest('/item', data)
    update = (id, data) => this.putRequest(`/item/${id}`, data)
    addGroup = (id, groupId) => this.postRequest(`/item/${id}/group/${groupId}`)
    publish = (id) => this.postRequest(`/item/${id}/publish`)

    getCoefficients = () => this.getRequest(`/resolutionCoefficient`)
    setCoefficient = (id, data) => this.putRequest(`/resolutionCoefficient/${id}/coefficient`, data)
    getFiles = () => this.getRequest(`/digitalProducts`)

    delete = id => this.deleteRequest(`/item/${id}`)
}

export default new Items();
