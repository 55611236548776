import React, { useState } from 'react';
import clsx from 'clsx';
import { Collapse } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import setupIcon from '../../assets/images/gear.svg';
import playIcon from '../../assets/images/play.svg';
import layersIcon from '../../assets/images/layers.svg';
import tagIcon from '../../assets/images/tag.svg';
import sizeIcon from '../../assets/images/size.svg';
import paymentIcon from '../../assets/images/payment.svg';
import basketIcon from '../../assets/images/basket.svg';
import htmlIcon from '../../assets/images/html.svg';
import report from '../../assets/images/report.svg';

import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import SidebarUserbox from '../SidebarUserbox';

import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone';

const Menu = {
  Items: '/items',
  "Music Album Types": '/musicAlbumTypes',
  Coefficient: '/coefficient',
  Tags: '/tags',
  Groups: '/groups',
  Packages: '/packages',
  Baskets: '/baskets',
  Settings: [
    {
      name: 'change password',
      link: '/main/change-password',
    },
  ],
  Files: '/files',
  Reports: '/reports'
}

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile, sidebarUserbox } = props;
  const [menuOpen, setMenuOpen] = useState(null);

  return (
    <>
      <PerfectScrollbar>
        {/* {sidebarUserbox && <SidebarUserbox />} */}
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span>Navigation menu</span>
          </div>
          <ul>
            {Object.keys(Menu).map((el, index) =>
              <li>
                {Array.isArray(Menu[el]) ?
                  <>
                    <a
                      href="#/"
                      onClick={(e) => { e.preventDefault(); setMenuOpen(st => st === index ? null : index) }}
                      className={clsx({ active: menuOpen === index })}>
                      <span className="sidebar-icon">
                        {el === "Items" ? <img src={playIcon} alt="items" /> :
                          el === "Music Album Types" ? <img src={report} alt="musicAlbumTypes" /> :
                          el === "Coefficient" ? <img src={sizeIcon} alt="coefficient" /> :
                          el === "Tags" ? <img src={tagIcon} alt="tags" /> :
                          el === "Groups" ? <img src={layersIcon} alt="groups" /> :
                          el === "Settings" ? <img src={setupIcon} alt="settings" /> :
                          el === "Packages" ? <img src={paymentIcon} alt="payment" /> :
                          el === "Baskets" ? <img src={basketIcon} alt="basket" /> :
                          el === "Files" ? <img src={htmlIcon} alt="files" /> :
                          el === "Reports" ? <img src={report} alt="files" /> :
                          <VerifiedUserTwoToneIcon />}
                      </span>
                      <span style={{
                        textTransform: 'capitalize'
                      }} className="sidebar-item-label">{el.replace('_',' ')}</span>
                      <span className="sidebar-icon-indicator">
                        <ChevronRightTwoToneIcon />
                      </span>
                    </a>
                    <Collapse in={menuOpen === index}>
                      <ul>
                        {Menu[el].map(e =>
                          <li>
                            <NavLink
                              exact
                              onClick={() => setSidebarToggleMobile(false)}
                              to={e.link}>
                              {e.name}
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </Collapse>
                  </> : <NavLink
                    to={Menu[el]}
                    onClick={() => { setSidebarToggleMobile(false); setMenuOpen(null) }}>
                    <span className="sidebar-icon">
                      {el === "Items" ? <img src={playIcon} alt="items" /> :
                        el === "Music Album Types" ? <img src={report} alt="musicAlbumTypes" /> :
                        el === "Coefficient" ? <img src={sizeIcon} alt="coefficient" /> :
                        el === "Tags" ? <img src={tagIcon} alt="tags" /> :
                        el === "Groups" ? <img src={layersIcon} alt="groups" /> :
                        el === "Settings" ? <img src={setupIcon} alt="settings" /> :
                        el === "Packages" ? <img src={paymentIcon} alt="payment" /> :
                        el === "Baskets" ? <img src={basketIcon} alt="basket" /> :
                        el === "Files" ? <img src={htmlIcon} alt="files" /> :
                        el === "Reports" ? <img src={report} alt="files" /> :
                          <VerifiedUserTwoToneIcon />}
                    </span>
                    <span className="sidebar-item-label">{el}</span>
                  </NavLink>
                }
              </li>
            )}
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
