import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WrapperSeamless = (props) => {
  const { sectionHeading, children, backUrl } = props;

  return (
    <div className="example-card-seamless mb-spacing-6">
      {sectionHeading && (
        <h5 className="display-4 mb-4 font-weight-bold">
          <Grid container spacing={1}>
            {backUrl && <Grid item><Link to={backUrl}><FontAwesomeIcon icon={['fas', 'arrow-left']} /></Link></Grid>}
            <Grid item>{sectionHeading}</Grid>
          </Grid>
        </h5>
      )}
      {children}
    </div>
  );
};

export default WrapperSeamless;
