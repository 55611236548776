import React, { Suspense, useState, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';
import DeleteConfirm from './components/delte_confirm';
import Login from './pages/login'
import { ThemeProvider } from '@material-ui/styles';
import { ChangePassword } from './pages/main';

import MuiTheme from './theme';

// Layout Blueprints

import { LeftSidebar } from './layout-blueprints';

//components
import useStore from './context';
import * as Api from './service'
import Analytics from 'pages/analytics';
import Tags from 'pages/tags';
import Groups from 'pages/groups';
import Coefficient from 'pages/coefficient';
import { Items, ItemsForm } from 'pages/items';
import MusicAlbums from 'pages/music-albums';
import CreateMusicAlbumType from 'pages/music-albums/create-music-album-type';
import Presets from 'pages/music-albums/presets';
import CreatePreset from 'pages/music-albums/create-preset';
import EditPreset from 'pages/music-albums/edit-preset';
import { Packages, PackagesForm } from 'pages/packages';
import { Baskets } from 'pages/baskets';
import Files from 'pages/files';
import ReportsList from 'pages/reports';

const Routes = () => {
  const [show, setShow] = useState(false);
  const { user, setUser } = useStore()
  const location = useLocation();

  useEffect(() => {
    const localRefresh = localStorage.getItem('refresh')
    const sessionRefresh = sessionStorage.getItem('refresh')
    if (localRefresh || sessionRefresh) {
      Api.main.refreshToken(JSON.stringify({ value: localRefresh || sessionRefresh }))
        .then(res => {
          if (localRefresh) {
            localStorage.setItem('token', res.accessToken)
          } else {
            sessionStorage.setItem('token', res.accessToken)
          }
          setUser({})
          setShow(true)
        })
        .catch(e => {
          localStorage.removeItem('token')
          localStorage.removeItem('refresh')
          sessionStorage.removeItem('token')
          sessionStorage.removeItem('refresh')
          setShow(true)
        })
    } else {
      setShow(true)
    }
  }, []);

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Please wait while we load the live preview examples
                  <span className="font-size-lg d-block text-dark">
                    This live preview instance can be slower than a real
                    production build!
                  </span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        {show && <Suspense fallback={<SuspenseLoading />}>
          {!user ? <Login /> :
            <LeftSidebar>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>

                  <Route path="/items/create/:id?" exact component={ItemsForm} />
                  <Route path="/items" exact component={Items} />
                  <Route path="/musicAlbumTypes" exact component={MusicAlbums} />
                  <Route path="/musicAlbumTypes/create/:id?" exact component={CreateMusicAlbumType} />
                  <Route path="/musicAlbumTypes/presets/:id?" exact component={Presets} />
                  <Route path="/musicAlbumTypes/presets/create/:id?" exact component={CreatePreset} />
                  <Route path="/musicAlbumTypes/presets/edit/:id?" exact component={EditPreset} />
                  <Route path="/groups" exact component={Groups} />
                  <Route path="/tags" exact component={Tags} />
                  <Route path="/coefficient" exact component={Coefficient} />
                  <Route path="/main/change-password" exact component={ChangePassword} />
                  <Route path="/" exact component={Analytics} />
                  <Route path="/packages/create/:id?" exact component={PackagesForm} />
                  <Route path="/packages" exact component={Packages} />
                  <Route path="/baskets" exact component={Baskets} />
                  <Route path="/files" exact component={Files} />
                  <Route path="/reports" exact component={ReportsList} />

                </motion.div>
              </Switch>
              <DeleteConfirm />
            </LeftSidebar>}
        </Suspense>}
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
