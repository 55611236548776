import React from 'react';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Save = ({ onClick = null }) => {
    return (
        <Button
            onClick={onClick}
            type='submit'
            className={`btn-primary shadow-none d-flex my-3 mx-auto `}
        >
            <span className="btn-wrapper--label">Save</span>
            <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['far', 'save']} />
            </span>
        </Button>
    );
};

export default Save;