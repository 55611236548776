import config from "../config";
import main from './main'
import queryString from 'query-string'
class Base {
    getRequest = (uri, query) => this.baseRequest('GET', uri, null, query)
    postRequest = (...rest) => this.baseRequest('POST', ...rest)
    deleteRequest = (...rest) => this.baseRequest('DELETE', ...rest)
    putRequest = (...rest) => this.baseRequest('PUT', ...rest)

    baseRequest = async (type, uri, data = null, query = null) => {
        const queryUrl = query ? `?${queryString.stringify(query, {
            skipEmptyString: true,
            skipNull: true,
        })}` : ''

        const fullUrl = config.apiBaseUrl + uri + queryUrl
        const res = await fetch(fullUrl, {
            method: type,
            headers: data instanceof FormData ? {
                Authorization: sessionStorage.getItem('token') || localStorage.getItem('token')
            } : {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token') || localStorage.getItem('token')
                },
            body: data,
        })

        if (!res.ok) {
            const err = await res.json()

            if (err.code === 40101 && uri !== '/login') {
                const localRefresh = localStorage.getItem('refresh')
                const sessionRefresh = sessionStorage.getItem('refresh')
                const res = await main.refreshToken(JSON.stringify({ value: localRefresh || sessionRefresh }))
                if (localRefresh) {
                    localStorage.setItem('token', res.accessToken)
                } else {
                    sessionStorage.setItem('token', res.accessToken)
                }
                return this.baseRequest(type, uri, data, query)
            } else {
                throw {
                    status: res.status,
                    message: err.message || 'something went wrong',
                    data: err,
                }
            }
        }

        const Type = res.headers.get('Content-Type')
        const isJson = Type?.includes('application/json')
        const isText = Type?.includes('text/plain')
        const isHtml= Type?.includes('text/html')
        const isBlob= Type?.includes('application/octet-stream')
        if (uri.includes('download') || isBlob) {
            const blobObj = { 
                blob: res.blob(), 
                disposition: res.headers.get("content-disposition")
            }
            return blobObj;
        }

        return isJson ? res.json() : isText || isHtml ? res.text() : true
    }
}
export default Base