import Base from './base';

class MusicAlbums extends Base {
    getTypeById = (id) => this.getRequest(`/musicAlbum/types/${id}`);
    getAllTypes = (query) => this.getRequest('/musicAlbum/types', query);
    countAllTypes = () => this.getRequest('/musicAlbum/types/count');
    create = (data) => this.postRequest('/musicAlbum/types', data);
    update = (id, data) => this.putRequest(`/musicAlbum/types/${id}`, data);
    deleteType = (id) => this.deleteRequest(`/musicAlbum/types/${id}`);

    getPresetById = (id) => this.getRequest(`/musicAlbum/preset/${id}`);
    createPreset = (data, id) =>
        this.postRequest(`/musicAlbum/presets/${id}`, data);
    updatePreset = (id, data) =>
        this.putRequest(`/musicAlbum/presets/${id}`, data);
    getAllPresets = (id, query) =>
        this.getRequest(`/musicAlbum/presets/${id}`, query);
    countAllPresets = (id, query) =>
        this.getRequest(`/musicAlbum/presets/count/${id}`, query);
    deletePreset = (id) => this.deleteRequest(`/musicAlbum/preset/${id}`);

    getAllTypesWithPresets = () =>
        this.getRequest(`/musicAlbum/typesWithPresets`);
    getItemAvailablePresets = (id) => this.getRequest(`/musicAlbum/itemPresets/${id}`);
}

export default new MusicAlbums();
