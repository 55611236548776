import React, { useEffect } from 'react';

const Analytics = () => {



    return (
        <div>
            
        </div>
    );
};

export default Analytics;