import React, { useCallback, useState } from 'react';

import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Table, Card } from '@material-ui/core';
import './style.scss';

import Pagination from '@material-ui/lab/Pagination';
import Button from '@material-ui/core/Button';
import DragTable from '../../drag_table';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';

export default function TableWithFilter({
  title,
  fields,
  rows,
  createLink,
  total,
  items,
  setItems,
  noDrag,
  orderCallback,
  actions = true
}) {
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory()

  const updatePage = useCallback((e, page) => {
    query.set('page', page)
    history.push(`?${query.toString()}`)
  }, [query.toString()]);

  const moveCard = (dragIndex, hoverIndex) => {
    setItems(items => {
      const updated = update(items, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, items[dragIndex]]
        ]
      })
      order(updated)
      return updated
    });
  };
  const order = useCallback(items => {
    const form = new FormData()
    items.forEach((el, i) => {
      form.append(`items[${i}][id]`, el._id)
      form.append(`items[${i}][i]`, i)
    })
    orderCallback(form)
  }, [items]);

  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-4">
          <div className="align-create-btn-between">
            <h6 className="font-weight-bold font-size-lg mb-0 text-black">
              {title}
            </h6>
            {createLink && <Button className="m-2 btn-neutral-primary" onClick={() => history.push(`/${createLink}`)} >Create</Button>}
          </div>
        </div>
        <div className="divider" />
        <div className="divider" />
        <div className="pt-4 px-4">
          <Table className="table table-alternate-spaced mb-0">
            <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
              <tr>
                {fields}
                {actions && <th width='100' className="text-right">Actions</th>}
              </tr>
            </thead>
            <DndProvider backend={Backend}>
              <tbody className='w100'>{rows?.map((el, i) =>
                !noDrag ? <DragTable
                  key={items[i]._id}
                  index={i}
                  moveCard={moveCard}
                  node={el}
                /> :
                  <>
                    <tr className={`px-2 py-2 m-2 my-3 font-weight-bold`}>
                      {el}
                    </tr>
                    <tr className="divider" />
                  </>
              )}</tbody>
            </DndProvider>
          </Table>
        </div>
        <div className="divider mt-3" />
        <div className="card-footer p-4 d-flex justify-content-center">

          <Pagination page={parseInt(query.get('page')) || 1} onChange={updatePage} className="pagination-primary" count={Math.ceil(parseInt(total) / 10) || 1} />
        </div>
      </Card>
    </>
  );
}
