import React from 'react';
import { Container, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetList } from '../../hooks';
import * as Api from '../../service';
import Table from '../../components/tables/table';
import { WrapperSeamless } from '../../layout-components';
import useStore from '../../context';

const fields = [
    <th className="text-left"></th>,
    <th className="text-left px-4">Type ID</th>,
    <th className="text-left">Name</th>,
    <th className="text-left">Photo</th>
];

export default function MusicAlbums() {
    const { setRemove } = useStore();
    const [items, total, refresh, setItems] = useGetList(
        Api.musicAlbums.getAllTypes,
        Api.musicAlbums.countAllTypes
    );

    const rows = items?.map((item) => (
        <>
            <td className="text-center" width="50px">
                <Link to={`/musicAlbumTypes/presets/${item.id}`}>
                    <Button variant="contained" color="primary" size="small">
                        Presets
                    </Button>
                </Link>
            </td>
            <td className="text-left">
                <div className="font-size-sm">{item.typeId}</div>
            </td>
            <td className="text-left">
                <div className="font-size-sm">{item.name}</div>
            </td>
            <td>
                <div className="font-size-sm">
                    {item.photo && (
                        <img
                            src={item.photo}
                            alt="music album type cover"
                            height="50"
                            maxWidth="100"
                        />
                    )}
                    {!item.photo && <span>No cover photo</span>}
                </div>
            </td>
            <td className="text-right" width="200px">
                <Link to={`/musicAlbumTypes/create/${item.id}`}>
                    <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                            icon={['far', 'edit']}
                            className="font-size-sm"
                        />
                    </Button>
                </Link>

                <Button
                    onClick={() =>
                        setRemove(() => () =>
                            Api.musicAlbums.deleteType(item.id).then(refresh)
                        )
                    }
                    className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                    <FontAwesomeIcon
                        icon={['fas', 'times']}
                        className="font-size-sm"
                    />
                </Button>
            </td>
        </>
    ));

    return (
        <Container maxWidth>
            <WrapperSeamless sectionHeading="Music Album Types">
                <Table
                    title="Types"
                    noDrag
                    items={items}
                    setItems={setItems}
                    total={total}
                    fields={fields}
                    rows={rows}
                    createLink="musicAlbumTypes/create"
                />
            </WrapperSeamless>
        </Container>
    );
}
