import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export const useGetList = (Api, ApiCount) => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState();
    const [total, setTotal] = useState(null);

    const queryUrl = new URLSearchParams(useLocation().search);

    const callback = useCallback(() => {
        if (Api) {
            setLoading(true);
            Api({
                offset: ((queryUrl.get('page') || 1) - 1) * 10,
                name: queryUrl.get('search'),
                limit: 10
            })
                .then((res) => {
                    setItems(res);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        if (ApiCount) {
            ApiCount()
                .then((res) => setTotal(res))
                .catch((err) => console.log(err));
        }
    }, [Api, queryUrl]);

    useEffect(callback, [Api, queryUrl.toString()]);

    return [items, total, callback, setItems];
};
