import React, { useState, createContext, useContext, useCallback, useRef } from 'react';
export const StoreContext = createContext()

export const Store = props => {
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [remove, setRemove] = useState(null);
  const [user, setUser] = useState(null);

  return (
    <StoreContext.Provider value={{
      successMsg, setSuccessMsg,
      errorMsg, setErrorMsg,
      remove, setRemove,
      user, setUser,
    }}>
      {props.children}
    </StoreContext.Provider >
  );
};

const useStore = () => useContext(StoreContext)

export default useStore