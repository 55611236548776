import React from 'react';
import { WrapperSeamless } from '../../layout-components';
import Table from '../../components/tables/table'
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetList } from '../../hooks';
import { Link } from 'react-router-dom'
import * as Api from '../../service';
import useStore from '../../context'


const fields = [
    <th className="text-left px-4" width="200" >Name</th>,
    <th className="text-left">Price</th>,
    <th className="text-left">Type</th>,
];


export default function Packages() {
    const { setRemove } = useStore()
    const [packages, total, refresh, setPackages] = useGetList(Api.packages.getAll);


    const rows = packages?.map(item =>
        <>
            <td className="text-left">
                <div>
                    <div className="font-size-sm opacity-7">{item.name}</div>
                </div>
            </td>

            <td className="text-left">
                <div className="font-size-sm opacity-7 clamp1">{item.salePrice}</div>
            </td>
            <td className="text-left">
                <div className="font-size-sm opacity-7">{item.type}</div>
            </td>
            <td className="text-right">
                <Link to={`/packages/create/${item.id}`}>
                    <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                            icon={['far', 'edit']}
                            className="font-size-sm"
                        />
                    </Button>
                </Link>

                <Button onClick={() => setRemove(() => () => Api.packages.delete(item.id).then(refresh))} className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                    <FontAwesomeIcon
                        icon={['fas', 'times']}
                        className="font-size-sm"
                    />
                </Button>
            </td>
        </>
    );

    return (
        <WrapperSeamless>
            <Table
                title='Packages'
                noDrag
                packages={packages}
                setPackages={setPackages}
                total={total}
                fields={fields}
                rows={rows} createLink="packages/create" />
        </WrapperSeamless>
    );
}
