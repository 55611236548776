import React from 'react';

import clsx from 'clsx';

import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Button, TextField, Card, Box, List, ListItem, IconButton } from '@material-ui/core';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import HeaderDots from '../../layout-components/HeaderDots';
import HeaderDrawer from '../../layout-components/HeaderDrawer';
import HeaderUserbox from '../../layout-components/HeaderUserbox';
import HeaderSearch from '../../layout-components/HeaderSearch';
import HeaderMenu from '../../layout-components/HeaderMenu';
import * as Api from '../../service'
import useStore from '../../context'

const Header = (props) => {
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile
  } = props;
  const { setUser } = useStore()

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const logOff = async () => {
    const localRefresh = localStorage.getItem('refresh')
    const sessionRefresh = sessionStorage.getItem('refresh')
    await Api.main.logOff(JSON.stringify({ value: localRefresh || sessionRefresh }))
    localStorage.removeItem('refresh')
    sessionStorage.removeItem('refresh')
    setUser(null)
  }

  return (
    <>
      <div
        className={clsx('app-header', {
          'app-header--shadow': headerShadow,
          'app-header--opacity-bg': headerBgTransparent
        })}>
        <div style={{
          width: '100%'
        }} className="app-header--pane w100">
          <button
            className={clsx(
              'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
              { 'is-active': sidebarToggleMobile }
            )}
            onClick={toggleSidebarMobile}>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          <HeaderSearch />
          <div style={{
            marginLeft: 'auto'
          }} className="text-center d-flex">
            <Button onClick={logOff} type='submit' variant="contained" className="btn-primary m-2">
              <span className="btn-wrapper--label">Log Out</span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
