import React, { useState, useCallback, useEffect } from 'react';
import {
    Container,
    Button,
    TextField,
    Card,
    Box,
    List,
    ListItem,
    Grid,
    ListItemIcon,
    ListItemText,
    Divider,
    IconButton,
    Switch
} from '@material-ui/core';
import Gallery from '../../components/gallery';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WrapperSeamless } from '../../layout-components';
import { useText, useGetData, usePutData, usePostData } from '../../hooks';
import useStore from '../../context';
import Select from '../../components/select';
import * as Api from '../../service';

const pages = [
    'main',
    'configuration',
    'music-album',
    'upload-types',
    'pictures',
    'meta'
];
const uploadTypes = ['SOUND', 'PHOTO', 'VIDEO', 'TEXT', 'COLOR'];

const initForm = {
    genId: '',
    name: '',
    slug: '',
    oldSlug: '',
    description: '',
    category: 'GENERATIVE',
    previewVideoUrl: '',
    basePrice: 0,
    salePercent: 0,
    saleBasePrice: 0,
    isPromoted: false,
    isRealtime: false,
    isSale: false,
    isMusicAlbum: false,
    hasNft: false,
    nftUrl: '',
    coverPhoto: null,
    status: 'DRAFT',
    groups: [],
    tags: [],
    photos: [],
    itemConfiguration: {
        availableResolutions: [],
        inputConfigurations: [],
        colors: []
    }
};
const textFields = ['name', 'description', 'slug', 'oldSlug'];
const resolutions = [
    { id: 'CUSTOM', name: 'custom' },
    { id: 'HD', name: 'hd' },
    { id: 'FHD', name: 'fhd' },
    { id: '_4K', name: '4k' }
];

const catData = [
    { id: 'OTHER', name: 'Other' },
    { id: 'GENERATIVE', name: 'Generative' }
];

const Form = () => {
    const { id } = useParams();
    const history = useHistory();
    const [data, loading, refresh] = useGetData(id && Api.items.getById, id);
    const [musicAlbumTypes] = useGetData(
        Api.musicAlbums.getAllTypesWithPresets
    );
    const [itemExceptedPresets] = useGetData(
        id && Api.musicAlbums.getItemAvailablePresets,
        id
    );
    const postCallback = usePostData(Api.items.create);
    const updateCallback = usePutData(Api.items.update, refresh);
    const [page, setPage] = useState('main');
    const { setErrorMsg, setSuccessMsg } = useStore();

    const [text, setText, textChange] = useText(data || initForm);
    const [editRes, setEditRes, change, editInput] = useText(null);

    const [newFile, setNewFile] = useState(null);
    const [newFiles, setNewFiles] = useState([]);
    const [images, setImages] = useState([]);

    const [musicAlbums, setMusicAlbums] = useState([]);
    const [metaConfigs, setMetaConfigs] = useState([]);
    const [selTags, setSelTags] = useState([]);
    const [selGroups, setSelGroups] = useState([]);

    const [tags] = useGetData(Api.tags.getAll);
    const [groups] = useGetData(Api.groups.getAllGroups);
    const [files, setFiles] = useState();

    useEffect(() => {
        if (itemExceptedPresets && musicAlbumTypes) {
            let excepted = [];
            let presets = [];
            itemExceptedPresets.forEach((type) => {
                type.presets.forEach((preset) => {
                    presets.push(`${type.id}_${preset.id}`);
                });
            });
            musicAlbumTypes.forEach((type) => {
                type.presets.forEach((preset) => {
                    const id = `${type.id}_${preset.id}`;
                    if (!presets.includes(id)) {
                        excepted.push(id);
                    }
                });
            });
            setMusicAlbums(excepted);
        }
    }, [itemExceptedPresets, musicAlbumTypes]);

    useEffect(() => {
        if (data) {
            setImages(data.photos);
            setSelTags(data.tags.map((el) => el.id));
            setSelGroups(data.groups.map((el) => el.id));
            setMetaConfigs(data.metaConfigs);
        }
    }, [data]);

    useEffect(() => {
        if (text?.category === 'GENERATIVE') {
            let configs = new Set();
            const soundConfig = {
                mediaType: 'SOUND',
                isMandatory: true
            };
            configs.add(soundConfig);
            text.itemConfiguration.inputConfigurations = [...configs];
            if (!data) {
                let res = new Set();
                resolutions.map((el) => {
                    el.id !== 'CUSTOM' &&
                        res.add({
                            resolution: el.id
                        });
                });
                text.itemConfiguration.availableResolutions = [...res];
            }
            setText((st) => ({ ...st }));
        } else {
            const filteredSound =
                text.itemConfiguration.inputConfigurations.filter(
                    (e) => e.mediaType !== 'SOUND'
                );
            text.itemConfiguration.inputConfigurations = filteredSound;
            if (!data) {
                text.itemConfiguration.availableResolutions = [];
            }
            setText((st) => ({ ...st }));
        }
    }, [text?.category]);

    useEffect(() => {
        Api.items
            .getFiles()
            .then((res) =>
                setFiles(res.map((el) => ({ name: el.key, id: el.key })))
            );
    }, []);

    const submitForm = useCallback(
        async (e) => {
            try {
                e.preventDefault();
                if (!text.name) return setErrorMsg('name required');
                if (
                    text.itemConfiguration.availableResolutions.some(
                        (el) => !el.resolution
                    )
                )
                    return setErrorMsg('select resolution');

                const form = new FormData();
                newFile && form.append('file', newFile[0]);
                newFile && form.append('context', 'ITEM_COVER');

                const url = newFile ? await Api.main.fileUpload(form) : null;

                const urls = !!newFiles?.length
                    ? await Promise.all(
                          newFiles.map((file) => {
                              const form = new FormData();
                              form.append('file', file);
                              form.append('context', 'ITEM_PHOTO');
                              return Api.main.fileUpload(form);
                          })
                      )
                    : null;

                if (text.category !== 'GENERATIVE') {
                    text.genId = '';
                }

                const presetIds = musicAlbums.map((musicAlbum) =>
                    parseInt(musicAlbum.substring(musicAlbum.indexOf('_') + 1))
                );

                const obj = JSON.stringify({
                    ...text,
                    tags: tags.filter((el) => selTags.includes(el.id)),
                    groups: groups
                        .filter((el) => selGroups.includes(el.id))
                        .map((el) => ({ id: el.id })),
                    photos: urls
                        ? [...urls.map((el) => el.url), ...images]
                        : images,
                    coverPhoto: url ? url.url : text.coverPhoto,
                    exceptPresets: presetIds,
                    metaConfigs: metaConfigs
                });

                const res = id
                    ? await updateCallback(id, obj)
                    : await postCallback(obj);
                !id && history.push(`/items/create/${res.id}`);
            } catch (e) {
                setErrorMsg(e.message);
            }
        },
        [
            text,
            id,
            newFile,
            newFiles,
            images,
            tags,
            selTags,
            groups,
            selGroups,
            musicAlbums,
            metaConfigs
        ]
    );

    const publish = useCallback(async () => {
        try {
            await Api.items.publish(id);
            setSuccessMsg('published');
            refresh();
        } catch (e) {
            setErrorMsg(e.message);
        }
    }, [id]);

    const editChange = (e) => {
        const { name, value } = e.target;
        setEditRes((st) => ({
            ...st,
            [name]: value !== '' ? parseFloat(value) || 0 : null
        }));
    };

    const toggleUploadTypeEnable = (el, val) => {
        if (val) {
            text.itemConfiguration.inputConfigurations.push({
                mediaType: el,
                isMandatory: false
            });
        } else {
            const index = text.itemConfiguration.inputConfigurations.findIndex(
                (e) => e.mediaType === el
            );
            text.itemConfiguration.inputConfigurations.splice(index, 1);
        }
        setText((st) => ({ ...st }));
    };

    const toggleResolutionType = (el, val) => {
        if (val) {
            let configs = new Set(text.itemConfiguration.availableResolutions);
            if (el.id !== 'CUSTOM') {
                configs.add({ resolution: el.id });
            } else {
                configs.add({ resolution: el.id });
                !editRes && setEditRes(el);
            }
            text.itemConfiguration.availableResolutions = [...configs];
        } else {
            text.itemConfiguration.availableResolutions.map((e, index) => {
                e.resolution === el.id &&
                    text.itemConfiguration.availableResolutions.splice(
                        index,
                        1
                    );
            });
        }
        setText((st) => ({ ...st }));
    };

    const resEdit = () => {
        text.itemConfiguration.availableResolutions.map((el, i) => {
            if (el.resolution === 'CUSTOM') {
                text.itemConfiguration.availableResolutions[i] = editRes;
            }
        });
        setText((st) => ({ ...st }));
    };

    const toggleMusicAlbumPreset = (presetId) => {
        if (musicAlbums.includes(presetId)) {
            setMusicAlbums(musicAlbums.filter((album) => album !== presetId));
        } else {
            setMusicAlbums([...musicAlbums, presetId]);
        }
    };

    const selectAllPresets = (musicAlbumType) => {
        const ids = [];
        musicAlbumType.presets.forEach((preset) => {
            ids.push(`${musicAlbumType.id}_${preset.id}`);
        });
        setMusicAlbums(musicAlbums.filter((album) => !ids.includes(album)));
    };

    const unselectAllPresets = (musicAlbumType) => {
        const ids = [];
        musicAlbumType.presets.forEach((preset) => {
            ids.push(`${musicAlbumType.id}_${preset.id}`);
        });
        const prevMusicAlbums = [...musicAlbums];
        ids.forEach((id) => {
            if (!prevMusicAlbums.includes(id)) {
                prevMusicAlbums.push(id);
            }
        });
        setMusicAlbums(prevMusicAlbums);
    };

    const setMetaConfigKey = (event, id) => {
        const { value } = event.target;
        setMetaConfigs((prev) => {
            return prev.map((metaConfig) => {
                if (metaConfig.id === id) {
                    metaConfig.metaKey = value;
                }
                return metaConfig;
            });
        });
    };

    const setMetaConfigValue = (event, id) => {
        const { value } = event.target;
        setMetaConfigs((prev) => {
            return prev.map((metaConfig) => {
                if (metaConfig.id === id) {
                    metaConfig.metaValue = value;
                }
                return metaConfig;
            });
        });
    };

    useEffect(() => {
        text.itemConfiguration.availableResolutions.map(
            (el) => el.resolution === 'CUSTOM' && setEditRes(el)
        );
        setText((st) => ({ ...st }));
    }, [text.itemConfiguration.availableResolutions]);

    return (
        <Container maxWidth>
            <WrapperSeamless sectionHeading={text.name} backUrl="/items">
                <Card className="shadow-xxl px-4 py-2">
                    <List
                        component="div"
                        className="my-3 nav-tabs nav-tabs-primary d-flex"
                    >
                        {pages
                            .filter((e) =>
                                e === 'upload-types'
                                    ? text.category === 'GENERATIVE'
                                    : true
                            )
                            .map((el) => (
                                <ListItem
                                    button
                                    disableRipple
                                    selected={page === el}
                                    onClick={() => setPage(el)}
                                >
                                    <p>
                                        {el === 'music-album' &&
                                            text.isMusicAlbum && (
                                                <Box component="span" mr={1}>
                                                    <FontAwesomeIcon icon="check" />
                                                </Box>
                                            )}
                                        {el.replace('-', ' ')}
                                    </p>
                                    <div className="divider" />
                                </ListItem>
                            ))}
                    </List>
                    <Box component="form" onSubmit={submitForm}>
                        {page === 'meta' && (
                            <>
                                <Grid container spacing={2} direction="column">
                                    <Grid item>
                                        <Button
                                            onClick={() => {
                                                setMetaConfigs([
                                                    ...metaConfigs,
                                                    {
                                                        id: Date.now(),
                                                        metaKey: '',
                                                        metaValue: ''
                                                    }
                                                ]);
                                            }}
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Add
                                        </Button>
                                        <div>Keys must be unique.</div>
                                    </Grid>
                                    {metaConfigs.map((config) => (
                                        <Grid
                                            item
                                            key={`meta-config-${config.id}`}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                                direction="row"
                                                alignItems="center"
                                            >
                                                <Grid item>
                                                    <TextField
                                                        variant="outlined"
                                                        className="my-2"
                                                        label="Key"
                                                        defaultValue={
                                                            config.metaKey
                                                        }
                                                        onChange={(e) =>
                                                            setMetaConfigKey(
                                                                e,
                                                                config.id
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        variant="outlined"
                                                        className="my-2"
                                                        label="Value"
                                                        defaultValue={
                                                            config.metaValue
                                                        }
                                                        onChange={(e) =>
                                                            setMetaConfigValue(
                                                                e,
                                                                config.id
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <IconButton
                                                        size="small"
                                                        title="Remove"
                                                        onClick={() =>
                                                            setMetaConfigs(
                                                                metaConfigs.filter(
                                                                    (
                                                                        metaConfig
                                                                    ) =>
                                                                        metaConfig.id !==
                                                                        config.id
                                                                )
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={[
                                                                'fa',
                                                                'trash'
                                                            ]}
                                                        />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </>
                        )}

                        {page === 'music-album' && (
                            <>
                                <Grid container spacing={2} direction="column">
                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid item className="heading-3">
                                            Enable
                                        </Grid>
                                        <Grid item>
                                            <Switch
                                                onChange={() =>
                                                    setText((st) => ({
                                                        ...st,
                                                        isMusicAlbum:
                                                            !text.isMusicAlbum
                                                    }))
                                                }
                                                checked={text.isMusicAlbum}
                                                color="primary"
                                                className="switch-medium"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <List>
                                            {musicAlbumTypes &&
                                                musicAlbumTypes.map(
                                                    (musicAlbumType) => (
                                                        <>
                                                            <ListItem>
                                                                <ListItemIcon>
                                                                    <IconButton
                                                                        size="small"
                                                                        title="Select all"
                                                                        onClick={() =>
                                                                            selectAllPresets(
                                                                                musicAlbumType
                                                                            )
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={[
                                                                                'far',
                                                                                'check-square'
                                                                            ]}
                                                                        />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        size="small"
                                                                        title="Unselect all"
                                                                        onClick={() =>
                                                                            unselectAllPresets(
                                                                                musicAlbumType
                                                                            )
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={[
                                                                                'far',
                                                                                'square'
                                                                            ]}
                                                                        />
                                                                    </IconButton>
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        musicAlbumType.name
                                                                    }
                                                                />
                                                            </ListItem>
                                                            <List
                                                                component="div"
                                                                disablePadding
                                                            >
                                                                {musicAlbumType.presets?.map(
                                                                    (
                                                                        preset
                                                                    ) => (
                                                                        <ListItem
                                                                            style={{
                                                                                paddingLeft:
                                                                                    '32px'
                                                                            }}
                                                                        >
                                                                            <ListItemIcon>
                                                                                <Switch
                                                                                    value={`${musicAlbumType.id}_${preset.id}`}
                                                                                    checked={
                                                                                        !musicAlbums.includes(
                                                                                            `${musicAlbumType.id}_${preset.id}`
                                                                                        )
                                                                                    }
                                                                                    color="primary"
                                                                                    className="switch-small"
                                                                                    onChange={() =>
                                                                                        toggleMusicAlbumPreset(
                                                                                            `${musicAlbumType.id}_${preset.id}`
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </ListItemIcon>
                                                                            <ListItemText
                                                                                primary={
                                                                                    preset.name
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    )
                                                                )}
                                                            </List>
                                                            <Divider />
                                                        </>
                                                    )
                                                )}
                                        </List>
                                    </Grid>
                                </Grid>
                            </>
                        )}

                        {page === 'main' && (
                            <>
                                {textFields.map((el) => (
                                    <TextField
                                        key={el}
                                        multiline
                                        variant="outlined"
                                        className="my-2"
                                        label={el}
                                        fullWidth
                                        name={el}
                                        value={text[el]}
                                        onChange={textChange}
                                    />
                                ))}

                                <TextField
                                    type="number"
                                    variant="outlined"
                                    className="my-2"
                                    label="Price"
                                    fullWidth
                                    name="basePrice"
                                    value={text.basePrice}
                                    onChange={textChange}
                                    InputProps={{
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                    onBlur={(e) => {
                                        const val = parseInt(e.target.value);
                                        setText((st) => ({
                                            ...st,
                                            basePrice: Number.isNaN(val)
                                                ? 0
                                                : val
                                        }));
                                    }}
                                />

                                <TextField
                                    variant="outlined"
                                    className="my-2"
                                    label="Video Preview Url"
                                    fullWidth
                                    name="previewVideoUrl"
                                    value={text.previewVideoUrl}
                                    onChange={textChange}
                                />

                                {tags && (
                                    <Select
                                        title="tags"
                                        value={selTags}
                                        onChange={setSelTags}
                                        multiple
                                        data={tags}
                                    />
                                )}
                                {groups && (
                                    <Select
                                        title="groups"
                                        value={selGroups}
                                        onChange={setSelGroups}
                                        multiple
                                        data={groups}
                                    />
                                )}

                                <Select
                                    title="category"
                                    onChange={(data) =>
                                        setText((st) => ({
                                            ...st,
                                            category: data
                                        }))
                                    }
                                    value={text.category}
                                    data={catData}
                                />

                                {text?.category === 'GENERATIVE' && (
                                    <TextField
                                        key={text.category}
                                        variant="outlined"
                                        className="my-2"
                                        label="genId"
                                        fullWidth
                                        required
                                        name="genId"
                                        value={text.genId}
                                        onChange={textChange}
                                    />
                                )}
                                <Card className="shadow-xxl px-4 py-2 d-flex">
                                    <div>
                                        <div className="heading-3">Promote</div>
                                        <div className="m-2">
                                            <Switch
                                                onChange={() =>
                                                    setText((st) => ({
                                                        ...st,
                                                        isPromoted:
                                                            !text.isPromoted
                                                    }))
                                                }
                                                checked={text.isPromoted}
                                                color="primary"
                                                className="switch-medium"
                                            />
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <div className="heading-3">Sale</div>
                                        <div className="m-2">
                                            <Switch
                                                onChange={() =>
                                                    setText((st) => ({
                                                        ...st,
                                                        isSale: !text.isSale
                                                    }))
                                                }
                                                checked={text.isSale}
                                                color="primary"
                                                className="switch-medium"
                                            />
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <div className="heading-3">
                                            Realtime
                                        </div>
                                        <div className="m-2">
                                            <Switch
                                                onChange={() =>
                                                    setText((st) => ({
                                                        ...st,
                                                        isRealtime:
                                                            !text.isRealtime
                                                    }))
                                                }
                                                checked={text.isRealtime}
                                                color="primary"
                                                className="switch-medium"
                                            />
                                        </div>
                                    </div>
                                </Card>

                                <Card className="shadow-xxl mt-2 px-4 py-2">
                                    <div>
                                        <div className="heading-3">NFT</div>
                                        <div className="m-2">
                                            <Switch
                                                onChange={() =>
                                                    setText((st) => ({
                                                        ...st,
                                                        hasNft: !text.hasNft
                                                    }))
                                                }
                                                checked={text.hasNft}
                                                color="primary"
                                                className="switch-medium"
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                key="nft-url"
                                                variant="outlined"
                                                className="my-2"
                                                label="NFT Url"
                                                fullWidth
                                                disabled={!text.hasNft}
                                                name="nftUrl"
                                                value={text.nftUrl}
                                                onChange={textChange}
                                            />
                                        </div>
                                    </div>
                                </Card>

                                <Card className="shadow-xxl px-4 my-3 py-2 d-flex">
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        className="my-2 w-50"
                                        label="Sale Percent"
                                        name="salePercent"
                                        value={text.salePercent}
                                        InputProps={{
                                            inputProps: {
                                                max: 100,
                                                min: 0
                                            }
                                        }}
                                        helperText={
                                            text.saleBasePrice > 0 &&
                                            'disabled if Sale Price'
                                        }
                                        disabled={text.saleBasePrice > 0}
                                        onBlur={(e) => {
                                            const val = parseInt(
                                                e.target.value
                                            );
                                            setText((st) => ({
                                                ...st,
                                                salePercent: Number.isNaN(val)
                                                    ? 0
                                                    : val
                                            }));
                                        }}
                                        onChange={textChange}
                                    />
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        className="my-2 w-50"
                                        label="Sale Price"
                                        name="saleBasePrice"
                                        value={text.saleBasePrice}
                                        onChange={(e) => {
                                            textChange(e);
                                            e.target.value > 0 &&
                                                setText((st) => ({
                                                    ...st,
                                                    salePercent: 0
                                                }));
                                        }}
                                        InputProps={{
                                            inputProps: {
                                                min: 0
                                            }
                                        }}
                                        onBlur={(e) => {
                                            const val = parseInt(
                                                e.target.value
                                            );
                                            setText((st) => ({
                                                ...st,
                                                saleBasePrice: Number.isNaN(val)
                                                    ? 0
                                                    : val
                                            }));
                                        }}
                                    />
                                </Card>
                            </>
                        )}

                        {page === 'configuration' && (
                            <>
                                <div className="d-flex my-2 p-2 align-items-center shadow-xxl">
                                    {files && (
                                        <>
                                            <Select
                                                noCard
                                                title="Select File"
                                                style={{ width: '100%' }}
                                                data={files}
                                                value={
                                                    text.itemConfiguration
                                                        .availableResolutions.el
                                                        ?.filePath
                                                }
                                                onChange={(val) => {
                                                    text.itemConfiguration.availableResolutions.filePath =
                                                        val;
                                                    setText((st) => ({
                                                        ...st
                                                    }));
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="d-flex flex-row-reverse justify-content-end">
                                    {resolutions.map((el) => (
                                        <div className="d-flex my-2 p-2 align-items-center flex-column">
                                            <div className="heading-3">
                                                {el.name}
                                            </div>
                                            <div>
                                                <Switch
                                                    checked={text.itemConfiguration.availableResolutions.some(
                                                        (e) =>
                                                            e.resolution ===
                                                            el.id
                                                    )}
                                                    onChange={(e, val) => {
                                                        toggleResolutionType(
                                                            el,
                                                            val
                                                        );
                                                    }}
                                                    color="primary"
                                                    className="switch-medium"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {text.itemConfiguration.availableResolutions.map(
                                    (el, i) =>
                                        el.resolution === 'CUSTOM' && (
                                            <div className="grid_custom_res">
                                                <div>
                                                    {' '}
                                                    Height
                                                    <TextField
                                                        {...editInput(
                                                            'customHeightMin'
                                                        )}
                                                        onChange={editChange}
                                                        onBlur={resEdit}
                                                        type="text"
                                                    />
                                                    <TextField
                                                        {...editInput(
                                                            'customHeightMax'
                                                        )}
                                                        onChange={editChange}
                                                        onBlur={resEdit}
                                                        type="text"
                                                    />
                                                </div>
                                                <div>
                                                    {' '}
                                                    Width
                                                    <TextField
                                                        {...editInput(
                                                            'customWidthMin'
                                                        )}
                                                        onChange={editChange}
                                                        onBlur={resEdit}
                                                        type="text"
                                                    />
                                                    <TextField
                                                        {...editInput(
                                                            'customWidthMax'
                                                        )}
                                                        onChange={editChange}
                                                        onBlur={resEdit}
                                                        type="text"
                                                    />
                                                </div>
                                                <div>
                                                    {' '}
                                                    Ratio X
                                                    <TextField
                                                        {...editInput(
                                                            'aspectRatioXMin'
                                                        )}
                                                        onChange={editChange}
                                                        onBlur={resEdit}
                                                        type="text"
                                                    />
                                                    <TextField
                                                        {...editInput(
                                                            'aspectRatioXMax'
                                                        )}
                                                        onChange={editChange}
                                                        onBlur={resEdit}
                                                        type="text"
                                                    />
                                                </div>
                                                <div>
                                                    {' '}
                                                    Ratio Y
                                                    <TextField
                                                        {...editInput(
                                                            'aspectRatioYMin'
                                                        )}
                                                        onChange={editChange}
                                                        onBlur={resEdit}
                                                        type="text"
                                                    />
                                                    <TextField
                                                        {...editInput(
                                                            'aspectRatioYMax'
                                                        )}
                                                        onChange={editChange}
                                                        onBlur={resEdit}
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        )
                                )}
                            </>
                        )}

                        {page === 'upload-types' && (
                            <div className="grid_upload_types">
                                {uploadTypes.map((el) => (
                                    <Card key={el} className="py-3">
                                        <div className="heading-3 pb-4 mb-6 text-center">
                                            {el}
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="mx-5">
                                                <div className="heading-3">
                                                    Enable
                                                </div>
                                                <div>
                                                    <Switch
                                                        checked={text.itemConfiguration.inputConfigurations.some(
                                                            (e) =>
                                                                e.mediaType ===
                                                                el
                                                        )}
                                                        onChange={(e, val) =>
                                                            toggleUploadTypeEnable(
                                                                el,
                                                                val
                                                            )
                                                        }
                                                        color="primary"
                                                        className="switch-medium"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mx-5">
                                                <div className="heading-3">
                                                    Required
                                                </div>
                                                <div>
                                                    <Switch
                                                        disabled={
                                                            !text.itemConfiguration.inputConfigurations.some(
                                                                (e) =>
                                                                    e.mediaType ===
                                                                    el
                                                            )
                                                        }
                                                        onChange={(e, val) => {
                                                            text.itemConfiguration.inputConfigurations.find(
                                                                (e) =>
                                                                    e.mediaType ===
                                                                    el
                                                            ).isMandatory = val;
                                                            setText((st) => ({
                                                                ...st
                                                            }));
                                                        }}
                                                        checked={
                                                            !!text.itemConfiguration.inputConfigurations.find(
                                                                (e) =>
                                                                    e.mediaType ===
                                                                    el
                                                            )?.isMandatory
                                                        }
                                                        color="primary"
                                                        className="switch-medium"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        )}
                        {page === 'pictures' && (
                            <>
                                <Gallery
                                    title="Cover Photo"
                                    single
                                    setNewFile={setNewFile}
                                    images={text.coverPhoto}
                                    setImages={(img) =>
                                        setText((st) => ({
                                            ...st,
                                            coverPhoto: img
                                        }))
                                    }
                                />
                                <Gallery
                                    title="Gallery"
                                    setNewFile={setNewFiles}
                                    images={images}
                                    setImages={setImages}
                                />
                            </>
                        )}
                        <div className="text-center mt-3">
                            <Button
                                type="submit"
                                variant="contained"
                                className="btn-primary m-2"
                            >
                                <span className="btn-wrapper--label">Save</span>
                                <span className="btn-wrapper--icon">
                                    <FontAwesomeIcon icon={['far', 'save']} />
                                </span>
                            </Button>
                            {id && (
                                <Button
                                    disabled={text.status === 'PUBLISHED'}
                                    type="button"
                                    onClick={publish}
                                    className="btn-primary m-2"
                                >
                                    <span className="btn-wrapper--label">
                                        Publish
                                    </span>
                                </Button>
                            )}
                        </div>
                    </Box>
                </Card>
            </WrapperSeamless>
        </Container>
    );
};

export default Form;
