import React, { useState, useCallback } from 'react';
import { Container, Button, TextField, Card, Box } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStore from '../../context'
import * as Api from '../../service'

const ChangePassword = () => {
    const { setRemove, setSuccessMsg, setErrorMsg } = useStore()
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const submitForm = useCallback(async e => {
        e.preventDefault()
        try {
            if (!currentPassword) return setErrorMsg('old password required')
            if (!password) return setErrorMsg('password required')
            if (password !== confirmPassword) return setErrorMsg('new password and confirm password are not same')
            await Api.main.changePassword(JSON.stringify({
                currentPassword,
                confirmPassword,
                newPassword: password
            }))
            setPassword('')
            setSuccessMsg('password changed')
        } catch (e) {
            setErrorMsg(e.message)
        }

    }, [password, currentPassword, confirmPassword]);

    return (
        <Container>
            <Card className="shadow-xxl p-4">
                <Box component='form' onSubmit={submitForm}>
                    <TextField
                        type='password'
                        variant="outlined"
                        className="mb-4"
                        label='old password'
                        fullWidth
                        value={currentPassword}
                        onChange={e => setCurrentPassword(e.target.value)}
                    />
                    <TextField
                        type='password'
                        variant="outlined"
                        className="mb-4"
                        label='new password'
                        fullWidth
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <TextField
                        type='password'
                        variant="outlined"
                        className="mb-4"
                        label='Confirm password'
                        fullWidth
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                    <div className="text-center">
                        <Button type='submit' variant="contained" className="btn-primary m-2">
                            <span className="btn-wrapper--label">Save</span>
                            <span className="btn-wrapper--icon">
                                <FontAwesomeIcon icon={['far', 'save']} />
                            </span>
                        </Button>
                    </div>
                </Box>
            </Card>

        </Container>
    );
};

export default ChangePassword;
