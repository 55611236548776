import React, { useState, useCallback } from 'react';

import { ReactComponent as Logo } from '../assets/icon.svg'
import {
    Grid,
    InputAdornment,
    FormControlLabel,
    Checkbox,
    Button,
    List,
    ListItem,
    Tooltip,
    TextField,
    Box
} from '@material-ui/core';

import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';

import hero8 from '../assets/images/hero-bg/hero-8.jpg';
import * as Api from '../service'
import useStore from '../context'

export default function LivePreviewExample() {
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [rem, setRem] = useState(false);
    const { setErrorMsg, setUser } = useStore()


    const submit = useCallback(async e => {
        e.preventDefault()
        try {
            if (!email || !pass) {
                return setErrorMsg('please fill in fields')
            }

            const res = await Api.main.login(JSON.stringify({ email, password: pass }))
            if (rem) {
                localStorage.setItem('token', res.accessToken)
                localStorage.setItem('refresh', res.refreshToken)
            } else {
                sessionStorage.setItem('token', res.accessToken)
                sessionStorage.setItem('refresh', res.refreshToken)
            }
            setUser({})
        } catch (e) {
            console.log(e)
            setErrorMsg(e.message)
        }
    }, [email, pass, rem]);

    return (
        <>
            <div className="app-wrapper min-vh-100 bg-white">
                <div className="app-main min-vh-100">
                    <div className="app-content p-0">
                        <div className="app-inner-content-layout--main">
                            <div className="flex-grow-1 w-100 d-flex align-items-center">
                                <div className="bg-composed-wrapper--content">
                                    <Grid container spacing={0} className="min-vh-100">
                                        <Grid
                                            item
                                            lg={7}
                                            xl={6}
                                            className="d-flex align-items-center">
                                            <Grid item md={10} lg={8} xl={7} className="mx-auto">
                                                <Box component='form' onSubmit={submit} className="py-4">
                                                    <div className="text-center">
                                                        <h1 className="display-4 mb-1 font-weight-bold">
                                                            Login
                            </h1>
                                                        <p className="font-size-lg mb-0 text-black-50">
                                                            Fill in the fields below to login to your account
                            </p>
                                                    </div>
                                                    <div className="text-center text-black-50 mb-4">
                                                        or sign in with credentials
                                                    </div>
                                                    <div>
                                                        <div className="mb-4">
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                id="textfield-email"
                                                                label="Email address"
                                                                value={email}
                                                                onChange={e => setEmail(e.target.value)}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <MailOutlineTwoToneIcon />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                id="textfield-password"
                                                                label="Password"
                                                                type="password"
                                                                value={pass}
                                                                onChange={e => setPass(e.target.value)}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <LockTwoToneIcon />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center font-size-md">
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={rem}
                                                                        onChange={e => setRem(!rem)}
                                                                        value="checked1"
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label="Remember me"
                                                            />
                                                        </div>
                                                        <div className="text-center py-4">
                                                            <Button variant='contained' color='primary' type='submit' className="font-weight-bold w-50 my-2">
                                                                Sign in
                                                             </Button>
                                                        </div>
                                                    </div>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={5} xl={6} className="d-flex">
                                            <div className="hero-wrapper w-100  min-vh-lg-100">
                                                <div className="flex-grow-1 w-100 d-flex align-items-center">
                                                    <div
                                                        style={{ top: 0, left: 0, position: 'absolute', height: '100%', width: '100%', backgroundColor: 'white' }}
                                                    />
                                                    <div className="bg-composed-wrapper--bg" />
                                                    <div className="bg-composed-wrapper--b" />
                                                    <div className="bg-composed-wrapper--content text-center p-5">
                                                        <div className="text-white px-0 px-lg-2 px-xl-4">
                                                            <Logo />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
