import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Container, Button, TextField, Card, Box, IconButton, Dialog, Typography, DialogTitle, DialogContentText, DialogContent, DialogActions } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Gallery from '../components/gallery'
import { useGetData, useText } from '../hooks'
import Save from '../components/save'
import useStore from '../context'
import * as Api from '../service'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ClearIcon from '@material-ui/icons/Clear';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import SvgIcon from '@material-ui/core/SvgIcon';
import EditIcon from '@material-ui/icons/Edit';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';



const Form = () => {
    const [data, loading, refresh] = useGetData(Api.items.getCoefficients)
    const { setRemove, setSuccessMsg, setErrorMsg } = useStore()
    const [text, setText] = useState();


    const submitForm = async (e) => {
        try {
            e.preventDefault()
            await Promise.all(data.map(el =>
                Api.items.setCoefficient(el.id, JSON.stringify({ value: parseFloat(el.coefficient) || 0 }))
            ))

            refresh()
            setSuccessMsg('updated')
        } catch (e) {
            setErrorMsg(e.message)
        }
    }

    return (
        <Container>
            <Card className="shadow-xxl px-4 py-2">
                <Box component='form' onSubmit={submitForm} >
                    {data?.sort((a, b) => a.id - b.id).map(el =>
                        <TextField
                            key={el.id}
                            type='number'
                            variant="outlined"
                            className="my-3"
                            label={el.resolution}
                            value={el.coefficient}
                            fullWidth
                            onChange={e => {
                                el.coefficient = e.target.value
                                setText({})
                            }}
                        />
                    )}
                    <Save />
                </Box>
            </Card>

        </Container >
    );
};

export default Form;