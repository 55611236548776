import React, { memo } from 'react'
import { useTheme, } from '@material-ui/core/styles';
import { Card, Grid, FormControl, Input, Select, MenuItem, InputLabel } from '@material-ui/core'
import Chip from '@material-ui/core/Chip';


const ITEM_HEIGHT = 24;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 13,
    },
  },
};


function CustomSelect({ name, multiple, style, title, data, value, onChange, className, noCard }) {


  const Sel = <FormControl style={style} fullWidth variant="outlined">
    <InputLabel>{title}</InputLabel>
    <Select
      name={name}
      multiple={!!multiple}
      onChange={e => onChange(e.target.value)}
      value={value}
      label={title}
      MenuProps={MenuProps}
      renderValue={multiple ? selected =>
        <div>
          {selected.map(id =>
            <Chip className="bg-primary text-white mx-1" key={id} label={data.find(e => e.id === id)?.name} />
          )}
        </div>
        : null}
    >
      {data.map(el =>
        <MenuItem key={el.id} value={el.id}>
          {el.name}
        </MenuItem>
      )}
    </Select>
  </FormControl>

  if (noCard) {
    return Sel
  }

  return (
    <Card className={`shadow-xxl px-4 my-3 py-3 ${className}`}>
      <Grid>
        {Sel}
      </Grid>
    </Card>
  );
}

export default memo(CustomSelect)