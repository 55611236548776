import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    Container,
    Card,
    TextField,
    Grid,
    Button,
    Box
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WrapperSeamless } from '../../layout-components';
import Gallery from '../../components/gallery';
import * as Api from '../../service';
import useStore from '../../context';
import { useText, useGetData, usePutData } from '../../hooks';

const initForm = {
    name: '',
    presetId: '',
    photo: null
};

export default function EditPreset() {
    const { id } = useParams();
    const history = useHistory();
    const [newFile, setNewFile] = React.useState(null);
    const { setErrorMsg } = useStore();
    const [data] = useGetData(id && Api.musicAlbums.getPresetById, id);
    const updateCallback = usePutData(Api.musicAlbums.updatePreset);
    const [text, setText, textChange] = useText(data || initForm);

    const onSubmit = React.useCallback(
        async (event) => {
            event.preventDefault();
            if (!text.name) return setErrorMsg('Name field is required');

            try {
                const form = new FormData();
                newFile && form.append('file', newFile[0]);
                newFile && form.append('context', 'MUSIC_ALBUM_PRESET_COVER');

                const url = newFile ? await Api.main.fileUpload(form) : null;

                const obj = JSON.stringify({
                    ...text,
                    photo: url ? url.url : text.photo
                });

                await updateCallback(id, obj);

                history.push(`/musicAlbumTypes/presets/${text.typeId}`);
            } catch (e) {
                setErrorMsg(e.message);
            }
        },
        [text, newFile, id]
    );

    return (
        <Container maxWidth>
            <WrapperSeamless
                backUrl={`/musicAlbumTypes/presets/${text.typeId}`}
                sectionHeading="Edit Preset">
                <Card className="shadow-xxl px-4 py-2">
                    <Box component="form" onSubmit={onSubmit}>
                        <Grid container direction="column">
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    className="my-2"
                                    label="Preset ID"
                                    required
                                    fullWidth
                                    name="presetId"
                                    value={text.presetId}
                                    onChange={textChange}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    className="my-2"
                                    label="Name"
                                    required
                                    fullWidth
                                    name="name"
                                    value={text.name}
                                    onChange={textChange}
                                />
                            </Grid>
                            <Grid item>
                                <Gallery
                                    title="Preset cover photo"
                                    single
                                    required
                                    images={text.photo}
                                    setNewFile={setNewFile}
                                    setImages={(img) =>
                                        setText((st) => ({ ...st, photo: img }))
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="btn-primary m-2">
                                    <span className="btn-wrapper--label">
                                        Save
                                    </span>
                                    <span className="btn-wrapper--icon">
                                        <FontAwesomeIcon
                                            icon={['far', 'save']}
                                        />
                                    </span>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </WrapperSeamless>
        </Container>
    );
}
