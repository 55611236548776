import React from 'react';
import { WrapperSeamless } from '../../layout-components';
import Table from '../../components/tables/table'
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetList } from '../../hooks';
import * as Api from '../../service';
import useStore from '../../context'
import personImg from '../../assets/images/person.svg'


const fields = [
    <th className="text-left">User Info</th>,
    <th className="text-left">Item</th>,
    <th className="text-left">Resolution</th>,
    <th className="text-left">logo</th>,
];


export default function Baskets() {
    const { setRemove } = useStore()
    const [baskets, total, refresh, setBaskets] = useGetList(Api.baskets.getAll);
    
    const rows = baskets?.map(item =>
        <>
            <td className="text-left">
                <div>
                    <div className="font-size-sm opacity-7">
                    <img src={item.itemSnapshotDto?.createdBy?.avatarPath || personImg} alt="logo" style={{ height: '25px', objectFit: 'cover', marginRight: '5px' }} />
                    {item.itemSnapshotDto?.createdBy?.fullName}</div>
                </div>
            </td>

            <td className="text-left">
                <div className="font-size-sm opacity-7 clamp1">{item.itemSnapshotDto?.item?.name}</div>
            </td>
            <td className="text-left">
                <div className="font-size-sm opacity-7">{item.itemSnapshotDto?.resolutionConfig?.resolution}</div>
            </td>
            <td className="text-left">
                <div className="font-size-sm opacity-7">
                    {item.itemSnapshotDto?.item?.coverPhoto && 
                        <img src={item.itemSnapshotDto.item.coverPhoto} alt="logo" style={{ height: '25px', objectFit: 'cover' }} 
                    />}
                </div>
            </td>
            <td className="text-right">
                <Button 
                    onClick={() => setRemove(() => () => Api.baskets.delete(item.id).then(refresh))} 
                    className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                            icon={['fas', 'times']}
                            className="font-size-sm"
                        />
                </Button>
            </td>
        </>
    );

    return (
        <WrapperSeamless>
            <Table
                title='Baskets'
                noDrag
                baskets={baskets}
                setBaskets={setBaskets}
                total={total}
                fields={fields}
                rows={rows}  
                />
        </WrapperSeamless>
    );
}
