import React from 'react';
import { Container, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useGetData } from '../../hooks';
import { WrapperSeamless } from '../../layout-components';
import Table from '../../components/tables/table';
import * as Api from '../../service';
import { useGetList } from '../../hooks';
import useStore from '../../context';

const fields = [
    <th className="text-left px-4">Preset ID</th>,
    <th className="text-left">Name</th>,
    <th className="text-left">Photo</th>
];

export default function Presets() {
    const { id } = useParams();
    const { setRemove } = useStore();
    const [musicAlbumType, loading] = useGetData(
        id && Api.musicAlbums.getTypeById,
        id
    );

    const getAllPresets = React.useCallback(
        (query) => Api.musicAlbums.getAllPresets(id, query),
        [id]
    );
    const countAllPresets = React.useCallback(
        (query) => Api.musicAlbums.countAllPresets(id, query),
        [id]
    );
    const [items, total, refresh, setItems] = useGetList(
        getAllPresets,
        countAllPresets
    );

    if (loading || musicAlbumType == null) {
        return null;
    }

    const rows = items?.map((item) => (
        <>
            <td className="text-left">
                <div className="font-size-sm">{item.presetId}</div>
            </td>
            <td className="text-left">
                <div className="font-size-sm">{item.name}</div>
            </td>
            <td>
                <div className="font-size-sm">
                    {item.photo && (
                        <img
                            src={item.photo}
                            alt="music album type cover"
                            height="50"
                            maxWidth="100"
                        />
                    )}
                    {!item.photo && <span>No cover photo</span>}
                </div>
            </td>
            <td className="text-right" width="200px">
                <Link to={`/musicAlbumTypes/presets/edit/${item.id}`}>
                    <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                            icon={['far', 'edit']}
                            className="font-size-sm"
                        />
                    </Button>
                </Link>

                <Button
                    onClick={() =>
                        setRemove(() => () =>
                            Api.musicAlbums.deletePreset(item.id).then(refresh)
                        )
                    }
                    className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                    <FontAwesomeIcon
                        icon={['fas', 'times']}
                        className="font-size-sm"
                    />
                </Button>
            </td>
        </>
    ));

    return (
        <Container maxWidth>
            <WrapperSeamless
                backUrl="/musicAlbumTypes"
                sectionHeading={`${musicAlbumType.id} - ${musicAlbumType.name} Presets`}>
                <Table
                    title="Presets"
                    noDrag
                    items={items}
                    setItems={setItems}
                    total={total}
                    fields={fields}
                    rows={rows}
                    createLink={`musicAlbumTypes/presets/create/${id}`}
                />
            </WrapperSeamless>
        </Container>
    );
}
