import { useCallback } from 'react';
import useStore from '../context';

export const usePutData = (Api, refresh) => {
    const { setSuccessMsg, setErrorMsg } = useStore();

    const callback = useCallback(
        async (data, id) => {
            try {
                await Api(data, id);
                refresh && refresh();
                setSuccessMsg('updated');
            } catch (e) {
                console.log(e);
                setErrorMsg(e.message || 'there was an error');
            }
        },
        [Api]
    );

    return callback;
};
