import React, { useCallback } from 'react';
import {
  Container,
  TextField,
  Card,
  Box,
  Switch,
  IconButton
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useText, useGetData, usePutData, usePostData } from '../../hooks';
import useStore from '../../context';
import Select from '../../components/select';
import Save from '../../components/save';
import * as Api from '../../service';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ClearIcon from '@material-ui/icons/Clear';

const initForm = {
  hasWatermark: false,
  limitCount: '',
  limitMinutes: '',
  name: '',
  note: '',
  price: '',
  salePercent: '',
  salePrice: '',
  saleText: '',
  textList: [''],
  type: 'MONTHLY',
  deleted: false,
  active: false,
  resolutionList: []
};

const resolutions = [
  { id: 'CUSTOM', name: 'CUSTOM' },
  { id: 'HD', name: 'HD' },
  { id: 'FHD', name: 'FHD' },
  { id: '_4K', name: '4K' }
];

const types = [
  { id: 'MONTHLY', name: 'Monthly' },
  { id: 'ANNUAL', name: 'Annual' }
];

const Form = () => {
  const { id } = useParams();
  const history = useHistory();
  const [data, loading, refresh] = useGetData(id && Api.packages.getById, id);
  const postCallback = usePostData(Api.packages.create);
  const updateCallback = usePutData(Api.packages.update, refresh);
  const { setErrorMsg, setSuccessMsg } = useStore();

  const [text, setText, textChange, input] = useText(data || initForm);

  const numCheck = (e) => {
    const { name, value } = e.target;
    const numberReg = /^\d*\.?\d*$/.test(value);
    setText((text) => ({
      ...text,
      [name]: numberReg ? value : value && text[name]
    }));
  };

  const submitForm = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const data = JSON.stringify({ ...text });
        id ? updateCallback(id, data) : postCallback(data);
      } catch (e) {
        setErrorMsg(e.message);
      }
    },
    [text, id, updateCallback, postCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Card className="shadow-xxl px-4 py-2 d-flex">
            <div>
              <div className="heading-3">Is active</div>
              <div className="m-2">
                <Switch
                  onChange={() =>
                    setText((st) => ({ ...st, active: !text.active }))
                  }
                  checked={text.active}
                  color="primary"
                  className="switch-medium"
                />
              </div>
            </div>
            <div className="ml-4">
              <div className="heading-3">Is deleted</div>
              <div className="m-2">
                <Switch
                  readOnly
                  checked={text.deleted}
                  color="primary"
                  className="switch-medium"
                />
              </div>
            </div>
          </Card>
          <br />
          <TextField
            className="my-2"
            label="Name"
            required
            variant="outlined"
            fullWidth
            name={`name`}
            value={text['name']}
            onChange={textChange}
          />

          <TextField
            className="my-2"
            label="Note"
            variant="outlined"
            fullWidth
            name={`note`}
            value={text['note']}
            onChange={textChange}
          />

          <TextField
            className="my-2"
            label="Count limit"
            variant="outlined"
            fullWidth
            name={`limitCount`}
            value={text['limitCount']}
            onChange={numCheck}
          />

          <TextField
            className="my-2"
            label="Minutes limit"
            variant="outlined"
            fullWidth
            name={`limitMinutes`}
            value={text['limitMinutes']}
            onChange={numCheck}
          />

          <TextField
            className="my-2"
            label="Price"
            variant="outlined"
            fullWidth
            name={`price`}
            value={text['price']}
            onChange={numCheck}
          />

          <TextField
            className="my-2"
            label="Sale percent"
            variant="outlined"
            fullWidth
            name={`salePercent`}
            value={text['salePercent']}
            onChange={numCheck}
          />

          <TextField
            className="my-2"
            label="Sale Price"
            variant="outlined"
            fullWidth
            name={`salePrice`}
            value={text['salePrice']}
            onChange={numCheck}
          />

          <TextField
            className="my-2"
            label="Sale Text"
            variant="outlined"
            fullWidth
            name={`saleText`}
            value={text['saleText']}
            onChange={textChange}
          />

          <Card className="card-box mb-spacing-4">
            <IconButton
              onClick={() => {
                text.textList.push('');
                setText((st) => ({ ...st }));
              }}>
              <AddCircleIcon color="primary" fontSize="large" />
            </IconButton>
            Add text
            {text.textList.map((el, i) => (
              <Card className="d-flex my-2 p-2 align-items-center shadow-xxl">
                <TextField
                  value={el}
                  onChange={(e) => {
                    text.textList[i] = e.target.value;
                    setText((st) => ({ ...st }));
                  }}
                  className="my-2"
                  label="Text"
                  variant="outlined"
                  fullWidth
                  name={`listText${i}`}
                  multiline
                />
                <IconButton
                  onClick={() => {
                    text.textList.splice(i, 1);
                    setText((st) => ({ ...st }));
                  }}>
                  <ClearIcon color="error" fontSize="large" />
                </IconButton>
              </Card>
            ))}
          </Card>

          <Select
            title="Resolutions"
            value={text['resolutionList'] || []}
            onChange={(data) => {
              setText((st) => ({ ...st, resolutionList: data }));
            }}
            multiple
            data={resolutions}
          />

          <Card className="shadow-xxl px-4 py-2 d-flex my-2">
            <div>
              <div className="heading-3">Has Watermark</div>
              <div className="m-2">
                <Switch
                  onChange={() =>
                    setText((st) => ({
                      ...st,
                      hasWatermark: !text.hasWatermark
                    }))
                  }
                  checked={text.hasWatermark}
                  color="primary"
                  className="switch-medium"
                />
              </div>
            </div>
            <div className="mrl-2">
              <div className="heading-3">Type</div>
              <div className="m-2">
                <Select
                  title="Select type"
                  onChange={(data) => setText((st) => ({ ...st, type: data }))}
                  value={text.type}
                  data={types}
                  noCard
                  style={{ width: '200px' }}
                />
              </div>
            </div>
          </Card>
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
