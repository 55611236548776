import React, { useState, useCallback } from 'react';
import { Container, Card, Box } from '@material-ui/core';
import * as Api from '../../service'
import Save from 'components/save';
import Upload from 'components/upload';


const Form = () => {
  const [aboutUsFile, setAboutUsFile] = useState(null)
  const [termsFile, setTermsFile] = useState(null)

  const submitForm = useCallback(async (e) => {
    try {
      e.preventDefault();
        if (aboutUsFile) {
            const data = new FormData();
            data.append("file", aboutUsFile[0])
            
            await Api.main.aboutUsUpload(data)
        } 
        if(termsFile) {
            const data = new FormData();
            data.append("file", termsFile[0])
            
            await Api.main.tcUpload(data)
        }

      } catch(error) {
          console.log(error)
      }

  }, [aboutUsFile, termsFile]);

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component='form' onSubmit={submitForm}>

          <Card className='p-3 m-2 align-items-center'>
            About-Us
             <Upload single setNewFile={setAboutUsFile} html />
          </Card>
          <Card className='p-3 m-2 align-items-center'>
            Terms and Conditionals
            <Upload single setNewFile={setTermsFile} html />
          </Card>

          {/* this code will be used */}
          {/* <Card className='p-3 m-2 align-items-center'>
            Privacy
            <Upload single setNewFile={setNewFile} html />
          </Card> */}

          <Save />
        </Box>
      </Card>

    </Container >
  );
};

export default Form;