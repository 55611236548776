import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Container, Button, TextField, Card, Box, IconButton, Dialog, Typography, DialogTitle, DialogContentText, DialogContent, DialogActions } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Gallery from '../../components/gallery'
import useStore from '../../context'
import * as Api from '../../service'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ClearIcon from '@material-ui/icons/Clear';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import SvgIcon from '@material-ui/core/SvgIcon';
import EditIcon from '@material-ui/icons/Edit';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
  icon: {
    cursor: 'pointer',
    visibility: 'hidden'
  },
  tree: {
    '&:hover': {
      '& .icon': {
        visibility: 'visible'
      }
    }
  },
});

const Form = () => {
  const classes = useStyles();
  const { setRemove, setSuccessMsg, setErrorMsg } = useStore()
  const [groups, setGroups] = useState(null);

  const [title, setTitle] = useState('');
  const [slug, setSlug] = useState('');
  const [coverPhoto, setCoverPhoto] = useState();

  const [editId, setEditId] = useState(null);
  const [parentId, setParentId] = useState(null);
  const [newItem, setNewItem] = useState(false);

  const [newFile, setNewFile] = useState();

  const key = useRef(0)

  const close = useCallback(() => {
    setNewItem(false)
    setParentId(null)
    setEditId(null)
    setTitle('')
    setCoverPhoto(null)
    setNewFile(null)
  }, []);

  const getGroups = useCallback((parent = null) => {
    if (groups && groups[parent]) return
    Api.groups.getAll({ parentId: parent })
      .then(res => {
        setGroups(st => ({ ...st ?? {}, [parent ?? 'all']: res.reverse() }))
      })
  }, [groups]);

  const submitForm = useCallback(async (e) => {
    try {
      e.preventDefault()
      if (!title) return setErrorMsg('title is required')
      if (!slug) return setErrorMsg('slug is required')

      const form = new FormData()
      newFile && form.append('file', newFile[0])
      newFile && form.append('context', 'GROUP_COVER')

      const url = newFile ? await Api.main.fileUpload(form) : null

      const res = await Api.groups.addNew(JSON.stringify({
        name: title,
        slug: slug,
        coverPhoto: url?.url
      }))
      if (res.id && parentId) {
        await Api.groups.parent({ groupId: res.id, parentId: parentId })
      }
      close()
      setSuccessMsg('created')
      setGroups(null)
      key.current++
      getGroups()
    } catch (e) {
      setErrorMsg(e.message)
    }
  }, [title, parentId, newFile, slug]);

  const editGroup = useCallback(async (e) => {
    try {
      e.preventDefault()
      if (!title) return setErrorMsg('title is required')
      if (!slug) return setErrorMsg('slug is required')

      const form = new FormData()
      newFile && form.append('file', newFile[0])
      newFile && form.append('context', 'GROUP_COVER')

      const url = newFile ? await Api.main.fileUpload(form) : null

      await Api.groups.edit(editId, JSON.stringify({
        name: title,
        slug: slug,
        coverPhoto: url ? url.url : coverPhoto
      }))
      setSuccessMsg('edited')
      close()
      setGroups(null)
      getGroups()
      key.current++

    } catch (e) {
      setErrorMsg(e.message)
    }
  }, [title, slug, editId, newFile, coverPhoto]);

  const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
    },
  }))((props) =>
    <div className={`d-flex my-1 tree`}>
      <TreeItem {...props} label={
        <div className={`d-flex my-1 align-items-center ${classes.tree}`}>
          <Typography variant="body2" >{props.el.name}</Typography>
          <AddIcon onClick={props.append} className={`ml-2 icon ${classes.icon}`} color='primary' />
          {!props.disable && <>
            <EditIcon onClick={props.edit} className={`ml-2 icon ${classes.icon}`} />
            <ClearIcon onClick={props.remove} className={`ml-2 icon ${classes.icon}`} color='error' />
          </>}
        </div>} />

    </div>
  );

  const TreeRender = (parent) => (
    groups[parent]?.map(el =>
      <StyledTreeItem
        id={el.id}
        el={el}
        disable={parent === 'all'}
        append={() => setParentId(el.id)}
        edit={() => { setEditId(el.id); setTitle(el.name); setSlug(el.slug); setCoverPhoto(el.coverPhoto) }}
        remove={() => setRemove(() => () => Api.groups.delete(el.id).then(() => {setGroups(null); getGroups()}))}
        onClick={() => el.childCount &&  getGroups(el.id)} nodeId={el.id} key={el.id} label={el.name}
      >
        {groups[el.id] ? TreeRender(el.id) : el.childCount ? <span /> : null}
      </StyledTreeItem>
    )
  )

  useEffect(getGroups, []);

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component='form' onSubmit={editId ? editGroup : submitForm}>

          {/* <IconButton className='ml-2' style={{ height: 58 }} onClick={() => setNewItem(true)} >
            <AddCircleIcon color='primary' fontSize='large' />
          </IconButton> */}

          <Dialog classes={{
            paper: `modal-content rounded-lg modal`
          }} open={editId || parentId || newItem} onClose={close} aria-labelledby="form-dialog-title">
            <DialogTitle>{editId ? 'Edit Group title' :  'Appent to current Group' }</DialogTitle>
            <DialogContent className="p-4">
              <DialogContentText style={{
                width: 500
              }} className="mb-0">
                <TextField
                  multiline
                  variant="outlined"
                  className="my-2 text-black-50"
                  label='tag title'
                  value={title}
                  fullWidth
                  onChange={e => setTitle(e.target.value)}
                />
                <TextField
                  multiline
                  variant="outlined"
                  className="my-2 text-black-50"
                  label='slug'
                  value={slug}
                  fullWidth
                  onChange={e => setSlug(e.target.value)}
                />
                <Gallery title='Image' single setNewFile={setNewFile} images={coverPhoto} setImages={setCoverPhoto} />
              </DialogContentText>
            </DialogContent>
            <DialogActions className="p-4">
              <Button onClick={close} variant="contained" type='button' className="m-2 btn-danger">Cancel</Button>
              <Button
                onClick={editId ? editGroup : submitForm}
                className={`${editId ? 'btn-success' : 'btn-primary'} shadow-none `}
              >
                <span className="btn-wrapper--label">{parentId ? 'Append' : 'Edit' }</span>
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['far', 'save']} />
                </span>
              </Button>
            </DialogActions>
          </Dialog>

          <TreeView
            key={key.current}
            style={{ height: 'auto' }}
            className={classes.root}
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            defaultEndIcon={<CloseSquare />}
          >
            {groups && TreeRender('all')}
          </TreeView>

        </Box>
      </Card>

    </Container >
  );
};

export default Form;
