import Base from './base';

class Groups extends Base {
    getPayments = (data) => this.getRequest('/reports/payments', data)
    getUsers = (data) => this.getRequest('/reports/users', data)
    getPurchasedItems = (data) => this.getRequest('/reports/purchased-items', data)

}

export default new Groups();
