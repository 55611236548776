import { useState, useCallback } from 'react'
import useStore from '../context'

export const usePostData = (Api, refresh) => {
  const { setSuccessMsg, setErrorMsg } = useStore()

  const callback = useCallback(async (data, id) => {
    try {
      const res = await Api(data, id)
      refresh && refresh()
      setSuccessMsg('added')
      return res
    } catch (e) {
      console.log(e);
      setErrorMsg(e.message || 'there was an error')
    }
  }, [Api]);


  return callback
}
