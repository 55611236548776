import Base from './base';

class Groups extends Base {
    getAll = (query) => this.getRequest('/guest/groups', query)
    addNew = data => this.postRequest('/groups', data)
    edit = (id, data) => this.putRequest(`/groups/${id}`, data)
    parent = (query) => this.putRequest(`/groups/parent`, null, query)
    delete = (id) => this.deleteRequest(`/groups/${id}`)
    getAllGroups = () => this.getRequest(`/groups/all`)
}

export default new Groups();
